import { gql, useQuery } from "@apollo/client";
import { Popover } from "@headlessui/react";
import { mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import { Link } from "react-router-dom";
import { imageIdToURL } from "../../utils/LinkUtils";
// @ts-ignore
import Logo from "../assets/logo_textless.png";

/*const navlinks = {
  left: [
    {
      title: "Teams",
      href: "/teams",
      dropdown: {
        1: [{ title: "", href: "", icon: "" }],
      },
    },
  ],
};*/

const GET_HEADER_TEAMS_QUERY = gql`
  query {
    team(sort: ["name", "suffix"]) {
      id
      name
      suffix
      slug
      game {
        name
        icon {
          id
        }
      }
    }
  }
`;

function Header() {
  let { loading, error, data } = useQuery(GET_HEADER_TEAMS_QUERY);
  return (
    <div className="h-16 z-50 absolute w-full text-gray-200 flex">
      {/*absolute w-full in order to allow for other pages to have images under header
      short term hassle in that you must add padding to the top of every page */}
      <div className="container mx-auto flex px-4">
        <div className="flex-1 flex items-center">
          <img alt="Brown Esports logo" className="w-8 sm:w-10" src={Logo} />
          <Link to="/">
            <h2 className="text-lg sm:text-2xl -mb-1 ml-3 sm:ml-4 sm:mr-6 font-header font-bold font-white text-white">
              Brown Esports
            </h2>
          </Link>
          {/* <Link to="/schedule" className="hidden sm:block">
            <h3 className="text-lg -mb-1 mx-4 font-body font-medium">
              Schedule
            </h3>
          </Link> */}
          <Popover.Group>
            <Popover className="relative">
              <Popover.Button className="text-lg -mb-1 mx-4 font-body font-medium hidden sm:block">
                Teams
              </Popover.Button>
              <Popover.Panel className="absolute z-10 w-screen max-w-2xl px-4 mt-2 -ml-2">
                <div className="relative bg-gray-650 rounded-md shadow-md">
                  <div className="absolute top-0 left-0 ml-6 -mt-3">
                    <div className="w-6 overflow-hidden inline-block">
                      <div className="block h-4 w-4 bg-gray-650 rotate-45 transform origin-bottom-left"></div>
                    </div>
                  </div>
                  <div className="flex px-2 gap-2">
                    <div className="w-1/2">
                      <div className="w-full">
                        {!loading && !error
                          ? data.team.map((team: any, i: number) => {
                              if (i % 2 === 0) {
                                return <HeaderBlock team={team} key={team.id + "headerteam"} />;
                              }
                              return null;
                            })
                          : null}
                      </div>
                    </div>
                    <div className="w-1/2">
                      <div className="w-full">
                        {!loading && !error
                          ? data.team.map((team: any, i: number) => {
                              if (i % 2 === 1) {
                                return <HeaderBlock team={team} key={team.id + "headerteam"} />;
                              }
                              return null;
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Popover>
          </Popover.Group>
          <Link to="/news" className="hidden sm:block">
            <h3 className="text-lg -mb-1 mx-4 font-body font-medium">News</h3>
          </Link>
          <Link to="/lounge" className="hidden sm:block">
            <h3 className="text-lg -mb-1 mx-4 font-body font-medium">Lounge</h3>
          </Link>
        </div>
        {/* <div className="flex-shrink items-center hidden sm:flex">
          <Link to="/contact">
            <h3 className="text-lg -mb-1 font-body font-medium">Contact</h3>
          </Link>
        </div> */}
        <div className="flex-shrink items-center flex sm:hidden text-white">
          <button>
            <Icon path={mdiMenu} size={1} />
          </button>
        </div>
      </div>
    </div>
  );
}

const HeaderBlock = (props: { team: any }) => {
  return (
    <Link to={`/teams/${props.team.slug}`}>
      <div
        className="flex hover:bg-gray-750 rounded-md p-2 my-2 items-center  transition-all duration-75"
      >
        <div className="bg-gray-800 w-12 h-12 rounded-md mr-4 flex-shrink-0">
          <img
            src={imageIdToURL(props.team.game.icon?.id)}
            alt={props.team.game.name + " logo"}
            className="w-8 h-8 m-2"
          />
        </div>
        <div className="flex flex-col">
          <div className="font-bold font-header text-sm tracking-wide text-white">
            {props.team.game.name}
          </div>
          {props.team.suffix ? (
            <div className="text-xs text-gray-200">{props.team.suffix}</div>
          ) : null}
        </div>
      </div>
    </Link>
  );
};

export default Header;
