import { DocumentNode, gql, useQuery } from "@apollo/client";

import MatchListing from "../../components/matchlisting/MatchListing";
import { imageIdToURL } from "../../utils/LinkUtils";
import ScoreHero from "./../../components/ScoreHero";
import ScoreHeroBackground from "./scorehero_background.png";
import ReactHelmet from "../../components/react-helmet/react-helmet";

interface Icon {
  id: string | null;
}

interface Game {
  name: string | null;
  icon: Icon | null;
}

interface Team {
  name: string | null;
  game: Game | null;
}

interface Match {
  name: string | null;
  date: string | null;
  time: string | null;
  team: Team | null;
  opponent: string | null;
  home_score: string | null;
  away_score: string | null;
}

interface MatchData {
  match: Match[];
}

export default function Matches(): JSX.Element {
  const GET_MATCHES_QUERY: DocumentNode = gql`
    query GetMatches {
      match(sort: "-date", limit: 8) {
        name
        date
        time
        team {
          name
          game {
            name
            icon {
              id
            }
          }
        }
        opponent
        home_score
        away_score
      }
    }
  `;

  const { loading, data } = useQuery<MatchData>(GET_MATCHES_QUERY);

  // Handle undefined cases for game name
  function getGameName(m: Match): string | null {
    if (m.team) {
      if (m.team.game) {
        return m.team.game.name;
      }
    }

    return null;
  }

  // Handle undefined cases for icon URL
  function getIconURL(m: Match): string | null {
    if (m.team) {
      if (m.team.game) {
        if (m.team.game.icon) {
          return imageIdToURL(m.team.game.icon.id);
        }
      }
    }

    return null;
  }

  // Handle undefined cases for team name
  function getTeamName(m: Match): string | null {
    if (m.team) {
      return m.team.name;
    }

    return null;
  }

  // Handle undefined cases for date
  function getDate(m: Match): string | null {
    let date: Date;
    let options = {};

    // fixes an issue where date displays incorrectly without time
    const ONE_HOUR = "01:00:00.000";

    if (m.date && m.time) {
      options = {
        weekday: "long" as const,
        month: "long" as const,
        day: "numeric" as const,
        hour: "numeric" as const,
        minute: "numeric" as const,
      };

      date = new Date(m.date + "T" + m.time);
    } else if (m.date && !m.time) {
      options = {
        weekday: "long" as const,
        month: "long" as const,
        day: "numeric" as const,
      };

      date = new Date(m.date + "T" + ONE_HOUR);
    } else {
      return null;
    }

    return date.toLocaleDateString("en-US", options);
  }

  // Chooses the correct component depending on the time of the game
  function gameCondition(m: Match): "live" | "scheduled" | "score" | null {
    const ONE_HOUR = "01:00:00.000"; // string repr. of 1 hour
    const TWO_HOURS = 7.2e6; // 2 hours in ms
    const today = new Date();

    if (m.date && m.time) {
      const gameTime = new Date(m.date + "T" + m.time);
      if (gameTime.getTime() < today.getTime()) {
        // Game before now
        if (today.getTime() - gameTime.getTime() < TWO_HOURS) {
          // Within two hour window
          return "live";
        } else {
          // Outside two hour window (still scheduled)
          return "score";
        }
      } else {
        // Game after now
        return "scheduled";
      }
    } else if (m.date && !m.time) {
      const gameTime = new Date(m.date + "T" + ONE_HOUR);
      if (gameTime.getTime() < today.getTime()) {
        // Game before now
        return "score";
      } else {
        // Game after now
        return "scheduled";
      }
    }

    // No date
    console.log("NO DATE FOR MATCH " + m.name);
    return null;
  }

  return (
    <div className="min-h-screen text-white bg-[#1C1917]">
      <ReactHelmet title="Matches" description="" />
      <img
        src={ScoreHeroBackground}
        alt="Background color"
        className="absolute right-0 top-0 min-w-max"
      />
      <ScoreHero transparent={true} />

      <div className="container mx-auto pt-6 px-8">
        {loading ? (
          // Loading
          <p>Loading...</p>
        ) : (
          // Data is loaded
          <>
            <h2 className="text-center font-bold text-3xl">Matches</h2>
            <p className="text-center italic text-sm">All times in Eastern</p>
            <ul className="mt-6 divide-y divide-[#44403C] pb-12">
              {data &&
                data.match.map((match, i) => (
                  <li className="py-3" key={"matchlisting" + i}>
                    <MatchListing
                      game={getGameName(match)}
                      name={match.name}
                      date={getDate(match)}
                      homeTeam={getTeamName(match)}
                      awayTeam={match.opponent}
                      homeScore={match.home_score}
                      awayScore={match.away_score}
                      icon={getIconURL(match)}
                      gameCondition={gameCondition(match)}
                    />
                  </li>
                ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
}
