import axios from "axios";
import RRule from "rrule";

export class RecurringEvent {
  public event: any;
  private rules: any;
  constructor(event: any) {
    this.event = event;
    this.rules = {
      start: RRule.fromString(
        "DTSTART:" +
          event.start.dateTime
            .slice(0, -6)
            .replace(/-/g, "")
            .replace(/:/g, "") +
          "\n" +
          event.recurrence[0]
      ),
      end: RRule.fromString(
        "DTSTART:" +
          event.end.dateTime.slice(0, -6).replace(/-/g, "").replace(/:/g, "") +
          "\n" +
          "RRULE:FREQ=WEEKLY"
      ),
    };
  }

  getNearestOccurrence = (
    start: number = 259200,
    end: number = 345600,
    all: boolean = false
  ) => {
    return this.getAllOccurrences(start, end)[0];
  };

  getAllOccurrences = (
    start: number = 7 * 24 * 60 * 60,
    end: number = 14 * 24 * 60 * 60
  ) => {
    let occurrences = this.rules.start.between(
      new Date(new Date().getTime() - start * 1000),
      new Date(new Date().getTime() + end * 1000)
    );
    let output = occurrences.map((date: any) => {
      let eventEnd = this.rules.end.between(
        date,
        new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000)
      )[0];
      return {
        start: {
          dateTime: date.toISOString().slice(0, -5) + "-05:00",
        },
        end: {
          dateTime: eventEnd.toISOString().slice(0, -5) + "-05:00",
        },
      };
    });
    return output.map((partial: any) => {
      return {
        ...this.event,
        ...partial,
      };
    });
  };
}

export const getSingleEvents = (events: any) => {
  return events.filter((item: any) => {
    // filter out events that have already passed
    // Does not support full-day events!
    // Exclude all events with recurrence (handle them later)
    return (
      new Date(item.end.dateTime) > new Date() && item.recurrence === undefined
    );
  });
};

export const getRecurringEvents = (events: any) => {
  return events.filter((item: any) => {
    return item.recurrence !== undefined;
  });
};

export class RoomTracker {
  private data: any;
  constructor() {
    this.data = {}
    this.update()
  }

  update = () => {
    return axios
      .get(
        "https://brownesports.bebooking.enes.tech/offices/1/map/?paginate=false" +
          senetDateString()
      )
      .then((res) => {
        this.data = res.data;
        return res.data;
      });
  };
}

export enum RoomStatus {
  AVAILABLE = "Available",
  CLOSED = "Closed",
  BOOKED = "Booked",
  FULL = "Full",
  UNKNOWN = "Unknown",
}

const senetDateString = () => {
  const now = new Date();
  const rounded_now = new Date(
    now.setHours(now.getHours(), now.getMinutes(), 0, 0)
  );
  const rounded_later = new Date(
    rounded_now.setMinutes(rounded_now.getMinutes() + 30)
  );
  return (
    "&from_date=" +
    rounded_now.toISOString() +
    "&to_date=" +
    rounded_later.toISOString()
  );
};
