import gql from "graphql-tag";

const GET_ARTICLES_QUERY = gql`
  query GetArticles($limit: Int = 1, $offset: Int = 0) {
    article(sort: "-publish_date", limit: $limit, offset: $offset) {
      title
      subtitle
      publish_date
      slug
      sort
      photo {
        id
      }
      tags {
        tags_id {
          text
          color
          sort
        }
      }
      authors {
        first_name
        last_name
        avatar {
          id
        }
      }
    }
  }
`;

export default GET_ARTICLES_QUERY;
