import { useQuery } from "@apollo/client";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import gql from "graphql-tag";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ArticleBlock from "../components/blog/ArticleBlock";
import Tag from "../components/blog/Tag";
import { imageIdToURL } from "../utils/LinkUtils";

var md = require("markdown-it")();

// import RelatedPosts from '../components/RelatedPosts';

const GET_NEWS_QUERY = gql`
  query GetNews($slug: String, $date: String) {
    article(
      filter: { slug: { _eq: $slug }, publish_date: { _eq: $date } }
      limit: 1
    ) {
      title
      subtitle
      publish_date
      content
      sort
      tags {
        tags_id {
          text
          color
        }
      }
      authors {
        first_name
        last_name
        avatar {
          id
        }
      }
      photo {
        id
      }
    }
  }
`;

type TagType = {
  text: string;
  color: string;
};

function BlogPost() {
  const { slug, date } = useParams(); // TODO: Implement date (not implemented due to Strapi bug)
  let { loading, error, data } = useQuery(GET_NEWS_QUERY, {
    variables: {
      slug,
      date,
    },
  });

  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  if (error) {
    toast.error(
      "Failed to establish a connection with the server. Please try again later.",
      { id: "noserverconnection", duration: Infinity }
    );
  }

  if (!loading && !error && !data.article[0]) {
    toast.error("This article doesn't exist.", {
      id: "noarticle",
      duration: Infinity,
    });
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden bg-gray-900">
      {/*  Page content */}
      <main className="flex-grow relative">
        <div className="absolute inset-0 h-[32rem] pt-16 box-content">
          <img
            className="absolute inset-0 w-full h-full object-cover opacity-25"
            src={require("../components/assets/hero-bg.jpg")}
            alt="About"
          />
          <div
            className="absolute inset-0 bg-gradient-to-t from-white dark:from-gray-900"
            aria-hidden="true"
          ></div>
        </div>
        {/*  Page sections */}
        <div className="relative max-w-6xl mx-auto mb-16">
          <div className="pb-12 md:pb-16">
            <div className="max-w-3xl mx-auto">
              <article>
                <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                  <div className="pt-32 md:pt-40">
                    {/* Featured article */}
                    <div className="max-w-3xl" data-aos="fade-down">
                      <article>
                        <header className="mb-8">
                          {/* Title and excerpt */}

                          {!loading && !error && data.article[0] ? (
                            <>
                              <div className="text-center md:text-left">
                                <h1
                                  className="text-5xl font-header font-bold mb-4 text-white leading-tight"
                                  data-aos="fade-down"
                                >
                                  {data.article[0].title}
                                </h1>
                                <p
                                  className="text-xl text-gray-600 dark:text-gray-400"
                                  data-aos="fade-down"
                                  data-aos-delay="150"
                                >
                                  {data.article[0].subtitle}
                                </p>
                              </div>
                              {/* Article meta */}
                              <div className="md:flex md:items-center md:justify-between mt-5">
                                {/* Author meta */}

                                <div
                                  className="flex items-center justify-center"
                                  data-aos="fade-down"
                                  data-aos-delay="300"
                                >
                                  <a href="#0">
                                    <img
                                      className="rounded-full flex-shrink-0 mr-3"
                                      src={
                                        data.article[0].authors
                                          ? data.article[0]?.authors?.avatar?.id
                                            ? imageIdToURL(
                                                data.article[0].authors.avatar
                                                  .id
                                              )
                                            : `https://ui-avatars.com/api/?name=${data.article[0].authors.first_name} ${data.article[0].authors.last_name}&color=cccccc&background=333333`
                                          : `https://ui-avatars.com/api/?name=Brown+Esports&color=cccccc&background=333333`
                                      }
                                      width="32"
                                      height="32"
                                      alt="Author 04"
                                    />
                                  </a>
                                  <div>
                                    <span className="text-gray-600 dark:text-gray-400">
                                      By{" "}
                                    </span>
                                    <a
                                      className="font-medium text-gray-800 dark:text-gray-300 hover:underline"
                                      href="#0"
                                    >
                                      {data.article[0]?.authors?.first_name &&
                                      data.article[0]?.authors?.last_name
                                        ? `${data.article[0].authors.first_name} ${data.article[0].authors.last_name}`
                                        : "Brown Esports"}
                                    </a>
                                    <span className="text-gray-600 dark:text-gray-400">
                                      {" "}
                                      ·{" "}
                                      {new Date(
                                        data.article[0]?.publish_date
                                      ).toLocaleDateString("en-us", {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                        timeZone: "UTC",
                                      })}
                                    </span>
                                  </div>
                                </div>
                                {data.article[0]?.tags ? (
                                  <div className="md:ml-auto md:block flex justify-center mt-4 md:mt-0">
                                    <ul className="flex flex-wrap text-xs font-medium">
                                      {data.article[0]?.tags?.map(
                                        (tag: TagType, i: number) => {
                                          return (
                                            <Tag
                                              color={tag.color}
                                              text={tag.text}
                                              key={"loadmoretags" + i}
                                            />
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                ) : null}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="w-2/3 bg-gray-600 h-16 mb-4 animate-pulse"></div>
                              <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                              <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                              <div className="w-24 bg-gray-600 h-6 animate-pulse"></div>
                            </>
                          )}
                        </header>
                      </article>
                    </div>
                  </div>
                </div>
                <hr
                  className="w-5 h-px pt-px bg-gray-400 dark:bg-gray-500 border-0 mb-8 ml-4 sm:ml-6"
                  data-aos="fade-down"
                  data-aos-delay="450"
                />
                {!loading && !error && data.article[0] ? (
                  <div
                    className="prose-content px-4 sm:px-6"
                    dangerouslySetInnerHTML={{
                      __html: md.render(data.article[0].content),
                    }}
                  ></div>
                ) : (
                  <div className="px-4 sm:px-6">
                    {[...Array(5)].map((_, i) => {
                      return (
                        <div className="w-full" key={"paragraphskeleton" + i}>
                          <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                          <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                          <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                          <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                          <div className="w-2/3 bg-gray-600 h-6 mb-4 animate-pulse"></div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </article>
            </div>
          </div>
          <div className="grid grid-cols-2 border-t border-b border-gray-600 mb-16 text-gray-200 py-2 mx-4 gap-4">
            {!loading &&
            !error &&
            data.article &&
            data?.article[0]?.publish_date ? (
              <NextArticle sort={data.article[0]?.sort} />
            ) : (
              <div className="h-[48px]"></div>
            )}

            {!loading &&
            !error &&
            data.article &&
            data?.article[0]?.publish_date ? (
              <LastArticle sort={data.article[0]?.sort} />
            ) : (
              <div className="h-[48px]"></div>
            )}
          </div>
          <div className="font-header font-bold text-4xl text-white mb-4 text-center mx-4">
            Related Articles
          </div>
          <div className="mx-4">
            {!loading && !error && data.article[0] ? (
              <RelatedArticles exclude={data.article[0].sort} />
            ) : (
              <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-6 md:gap-y-8 items-start">
                {[...Array(3)].map((_, i) => {
                  return (
                    <div
                      className="flex flex-col h-full"
                      key={"relatedarticlesskeleton" + i}
                    >
                      <figure className="relative h-0 pb-9/16 bg-gray-600 animate-pulse mb-4"></figure>
                      <figure className="h-6 w-48 bg-gray-600 animate-pulse mb-4"></figure>
                      <figure className="h-4 w-full bg-gray-600 animate-pulse mb-2"></figure>
                      <figure className="h-4 w-full bg-gray-600 animate-pulse mb-2"></figure>
                      <figure className="h-4 w-24 bg-gray-600 animate-pulse mb-2"></figure>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {/* <RelatedPosts /> */}
      </main>
    </div>
  );
}

const GET_NEXT_ARTICLE = gql`
  query getNextArticle($sort: Float!) {
    article(sort: "sort", limit: 1, filter: { sort: { _gt: $sort } }) {
      title
      publish_date
      slug
    }
  }
`;

function NextArticle(props: { sort: number }) {
  let { loading, error, data } = useQuery(GET_NEXT_ARTICLE, {
    variables: { sort: props.sort },
  });
  if (loading || error) {
    return <div className="h-[48px]"></div>;
  }
  if (!loading && !error && data.article[0]) {
    return (
      <Link
        to={`/news/${data.article[0].publish_date}/${data.article[0].slug}`}
        className="flex items-center"
      >
        <Icon path={mdiChevronLeft} size={2} className="flex-shrink-0" />
        <div className="justify-center">
          <div className="font-medium text-gray-200">
            {data.article[0].title}
          </div>
          <div className="text-sm font-medium text-gray-400">
            {new Date(data.article[0]?.publish_date).toLocaleDateString(
              "en-us",
              {
                year: "numeric",
                month: "short",
                day: "numeric",
                timeZone: "UTC",
              }
            )}
          </div>
        </div>
      </Link>
    );
  }
  return <div></div>;
}

const GET_LAST_ARTICLE = gql`
  query getLastArticle($sort: Float!) {
    article(sort: "-sort", limit: 1, filter: { sort: { _lt: $sort } }) {
      title
      publish_date
      slug
    }
  }
`;

function LastArticle(props: { sort: number }) {
  let { loading, error, data } = useQuery(GET_LAST_ARTICLE, {
    variables: { sort: props.sort },
  });
  if (loading || error) {
    return <div className="h-[48px]"></div>;
  }
  if (!loading && !error && data.article[0]) {
    return (
      <Link
        to={`/news/${data.article[0].publish_date}/${data.article[0].slug}`}
        className="flex items-center text-right ml-auto"
      >
        <div className="justify-center">
          <div className="font-medium text-gray-200">
            {data.article[0].title}
          </div>
          <div className="text-sm font-medium text-gray-400">
            {new Date(data.article[0]?.publish_date).toLocaleDateString(
              "en-us",
              {
                year: "numeric",
                month: "short",
                day: "numeric",
                timeZone: "UTC",
              }
            )}
          </div>
        </div>
        <Icon path={mdiChevronRight} size={2} className="flex-shrink-0" />
      </Link>
    );
  }
  return null;
}

const GET_ARTICLES_QUERY = gql`
  query GetArticles($limit: Int!, $exclude: Float = -1) {
    article(sort: "-sort", limit: $limit, filter: { id: { _neq: $exclude } }) {
      title
      subtitle
      publish_date
      slug
      sort
      tags {
        tags_id {
          text
          color
          sort
        }
      }
      authors {
        first_name
        last_name
        avatar {
          id
        }
      }
      photo {
        id
      }
    }
  }
`;

export function RelatedArticles(props: {
  exclude?: number;
  onlyshow?: number;
}) {
  let { loading, error, data } = useQuery(GET_ARTICLES_QUERY, {
    variables: { limit: 4, exclude: props.exclude },
  });

  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  if (error) {
    toast.error(
      "Failed to establish a connection with the server. Please try again later.",
      { id: "noserverconnection", duration: Infinity }
    );
    console.error(error);
  }
  return (
    <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-6 md:gap-y-8 items-start">
      {!loading && !error
        ? data.article.map((item: any, i: number) => {
            if (props.onlyshow !== -1 && props.onlyshow !== undefined && i !== props.onlyshow) {
              return null;
            }
            return (
              <ArticleBlock
                title={item.title}
                subtitle={item.subtitle}
                link={`/news/${item.publish_date}/${item.slug}`}
                author_name={
                  item.authors
                    ? `${item.authors.first_name} ${item.authors.last_name}`
                    : "Unknown Author"
                }
                profile_link="/"
                date={new Date(item.publish_date).toLocaleDateString("en-us", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  timeZone: "UTC",
                })}
                tags={item.tags}
                image_link={imageIdToURL(item?.photo?.id)}
                author_image={item.profile?.avatar?.id}
                key={"loadmore" + i}
                hidelast={true}
              />
            );
          })
        : [...Array(3)].map((_, i) => {
            return (
              <div
                className="flex flex-col h-full"
                key={"loadmoreskeletonblock" + i}
              >
                <figure className="relative h-0 pb-9/16 bg-gray-600 animate-pulse mb-4"></figure>
                <figure className="h-6 w-48 bg-gray-600 animate-pulse mb-4"></figure>
                <figure className="h-4 w-full bg-gray-600 animate-pulse mb-2"></figure>
                <figure className="h-4 w-full bg-gray-600 animate-pulse mb-2"></figure>
                <figure className="h-4 w-24 bg-gray-600 animate-pulse mb-2"></figure>
              </div>
            );
          })}
    </div>
  );
}
export default BlogPost;
