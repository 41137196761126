import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

var md = require("markdown-it")();

const GET_PAGE = gql`
  query getPage($slug: String) {
    pages(filter: { slug: { _eq: $slug } }, limit: 1) {
      title
      data
    }
  }
`;

const PageRouter = () => {
  let location = useLocation();
  let { loading, error, data } = useQuery(GET_PAGE, {
    variables: {
      slug: location.pathname,
    },
  });

  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  if (error) {
    toast.error(
      "Failed to establish a connection with the server. Please try again later.",
      { id: "noserverconnection", duration: Infinity }
    );
    console.error(error);
  }

  if (!loading && !error && !data.pages[0]) {
    toast.error(
      "This page doesn't exist. The site is under construction, so please check back soon.",
      { id: "noserverconnection", duration: Infinity }
    );
    console.error(error);
  }

  return (
    <div className="bg-gray-900 py-24">
      <div className="max-w-3xl mx-auto">
        {!loading && !error && data.pages[0] ? (
          <>
            <h1 className="px-4 sm:px-6 text-4xl md:text-6xl font-bold font-header text-white mb-8 text-center">{data.pages[0].title}</h1>
            <div
              className="prose-content px-4 sm:px-6"
              dangerouslySetInnerHTML={{
                __html: md.render(data.pages[0].data),
              }}
            ></div>
          </>
        ) : (
          <div className="px-4 sm:px-6">
            {[...Array(5)].map((_, i) => {
              return (
                <div className="w-full" key={"paragraphskeleton" + i}>
                  <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                  <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                  <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                  <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                  <div className="w-2/3 bg-gray-600 h-6 mb-4 animate-pulse"></div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PageRouter;
