import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { Component } from "react";

import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Home from "./pages/Home";
import GenericTextPage from "./pages/NewsText";
import Matches from "./pages/Matches/Matches";
import News from "./pages/News";
//import NotFound from "./pages/NotFound";
import Schedule from "./pages/Schedule";
import Teams from "./pages/Teams";
import { Toaster } from "react-hot-toast";
import Leadership from "./pages/Leadership";
import {Booking, BookingDisplay} from "./pages/Booking";
import Profile from "./pages/Profile";

import ReactGA from 'react-ga';
import PageRouter from "./pages/Pages";
import { Helmet } from "react-helmet"; 
ReactGA.initialize('UA-163609869-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export class App extends Component<{}, {}> {
  withHeaderFooter = (page: JSX.Element) => {
    return (
      <>
        <Header />
        {page}
        <Footer />
      </>
    );
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Brownesports</title>
          <meta name="description" content="Brownesports is a website for the Brownesports community. It contains information about the club, the schedule, and the teams." />
        </Helmet>
        <BrowserRouter>
          <Toaster
            toastOptions={{
              className: "text-white bg-gray-800",
            }}
          />
          {/* <Header isLogged={this.state.isLogged} logout={this.logout} /> */}

          {/* Route config */}
          <Routes>
            <Route path="/" element={this.withHeaderFooter(<Home />)} />
            <Route
              path="/schedule"
              element={this.withHeaderFooter(<Schedule />)}
            />
            <Route path="/teams" element={this.withHeaderFooter(<Teams />)} />
            <Route path="/news" element={this.withHeaderFooter(<News />)} />
            <Route
              path="/news/:date/:slug"
              element={this.withHeaderFooter(<GenericTextPage />)}
            />
            <Route
              path="/text"
              element={this.withHeaderFooter(<GenericTextPage />)}
            />
            <Route
              path="/matches"
              element={this.withHeaderFooter(<Matches />)}
            />
            <Route
              path="/leadership"
              element={this.withHeaderFooter(<Leadership />)}
            />
            <Route path="/booking" element={<Booking />} />
            <Route path="/booking/display" element={<BookingDisplay />} />
            <Route
              path="/profile/:id"
              element={this.withHeaderFooter(<Profile />)}
            />
            <Route path="*" element={this.withHeaderFooter(<PageRouter />)} />
          </Routes>

          {/* <Footer /> */}
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
