import React, { Component, ReactNode } from "react";

import { ReactComponent as DetailsArrow } from "./../assets/details_arrow.svg";
import Live from "./Live";
import Scheduled from "./Scheduled";
import Score from "./Score";

type Props = {
  game: string | null,
  name: string | null,
  date: string | null,
  homeTeam: string | null,
  awayTeam: string | null,
  homeScore: string | null,
  awayScore: string | null,
  icon: string | null,
  gameCondition: "live" | "score" | "scheduled" | null
};

// TODO: Create a placeholder icon
const PLACEHOLDER_ICON = "./";

export default class MatchListing extends Component<Props, {}> {
  // Render the correct component based on game condition
  renderGameCondition(): ReactNode {
    switch (this.props.gameCondition) {
      case "live":
        return <Live />;
      case "score":
        return <Score homeScore={this.props.homeScore} awayScore={this.props.awayScore} />
      case "scheduled":
        return <Scheduled />
      case null:
        return <></>
      default:
        console.error("Invalid branch reached in renderGameCondition(). Please report this error.");
        return <></>
    }
  }

  render(): ReactNode {
    return (
      <React.Fragment>
        <div className="flex flex-col">
          <div className="flex flex-row justify-start items-center align">
            <div className="flex flex-row flex-1 items-center justify-left">
              {/* Logo */}
              {<img src={this.props.icon ? this.props.icon : PLACEHOLDER_ICON} className="w-8 h-8 mx-4" alt="" />}

              {/* Date */}
              <p>{this.props.date ? this.props.date : ""}</p>
            </div>

            <div className="flex flex-row flex-1 justify-center space-x-2">
              {/* Home Team */}
              <span className="flex flex-1 flex-col items-end justify-center">
                <p className="text-brownu-red font-bold">Brown Esports</p>
                <p className="text-gray-400">{this.props.homeTeam ? this.props.homeTeam : ""}</p>
              </span>

              {/* Score */}
              <span className="flex flex-1 justify-center items-start">
                {this.renderGameCondition()}
              </span>

              {/* Away Team */}
              <span className="flex flex-1 flex-col items-start justify-center">
                <p className="text-brownu-taupe font-bold">{this.props.awayTeam ? this.props.awayTeam : ""}</p>
              </span>
            </div>

            <div className="flex flex-row flex-1 items-center justify-right">
              {/* Details */}
              <button className="ml-auto font-bold">
                <p className="inline">Details</p>
                <DetailsArrow className="inline" />
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

}