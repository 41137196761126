import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

const Loading = (props: { visible: boolean }) => {
  return (
    <div
      className={
        "absolute w-full h-full flex items-center justify-center top-0 cursor-wait " +
        (props.visible ? "inline-block" : "hidden")
      }
    >
      <Icon path={mdiLoading} size={1} className="animate-spin" />
    </div>
  );
};

export default Loading;
