import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list';
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import { useEffect/*, useState */} from "react";
import { RoomTracker } from "../../utils/Events";
import { Link } from "react-router-dom";

const Booking = () => {
  //const [roomState, setRoomState] = useState();

  useEffect(() => {
    const roomClass = new RoomTracker()

    const roomUpdate = setInterval(() => {
      roomClass.update().then((res : any) => {
        console.log("res", res)
      })
    }, 60 * 1000)
    roomClass.update().then((res : any) => {
      console.log("res", res)
    })
    return () => {
      clearInterval(roomUpdate)
    }
  }, [])
  
  return (
    <div className="bg-gray-900 text-gray-200">
      <div className="max-w-6xl mx-auto min-h-screen py-24">
        <Link to="/booking/display" className="block py-12 text-white font-header font-bold text-lg underline">You can find the number of available PCs here - check the bottom left corner.</Link>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, googleCalendarPlugin]}
          initialView="timeGridWeek"
          // This API key is tied to br*wnesp*rtsteam@gmail.c*m
          // (partially censored to stop web scrapers)
          // and is used to access the Google Calendar API
          // It's domain-restricted to brownesports.org
          // and is read-only
          // So if you're traversing this code
          // Go away and get your own API key instead
          googleCalendarApiKey="AIzaSyDUaL1DCAMiGwwMnln-mX1GUVkQjlL07d0"
          events={{
            googleCalendarId:
              "oopgopec735424pl23g72eo8v8@group.calendar.google.com",
          }}
          nowIndicator={true}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,listWeek",
          }}
        />
      </div>
    </div>
  );
};

export default Booking;
