import BlogHero from "../components/blog/BlogHero";
import BlogList from "../components/blog/BlogList";

export default function News() {
  return (
    <div className="relative bg-gray-900">
      <BlogHero />
      <BlogList />
    </div>
  );
}
