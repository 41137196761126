export default function Live() {
  return (
    <div
      className="flex flex-shrink items-center bg-opacity-20 px-2 py-1 rounded-md mr-2"
      style={{ backgroundColor: "#FF000033" }}
    >
      <span className="font-bold" style={{ color: "#F00" }}>
        Live
      </span>
      <span className="flex w-3 h-3 ml-2">
        <span
          className="animate-ping absolute inline-flex w-3 h-3 rounded-full opacity-75"
          style={{ backgroundColor: "#F00" }}
        />
        <span
          className="relative inline-flex rounded-full h-3 w-3"
          style={{ backgroundColor: "#F00" }}
        />
      </span>
    </div>
  );
}