import { Component, ReactNode } from "react";

type Props = {
  homeScore: string | null,
  awayScore: string | null
};

export default class Score extends Component<Props, {}> {
  render(): ReactNode {
    return (
      <div className="flex flex-col space-y-1 items-center">
        <p className="text-xs font-bold text-gray-500">FINAL</p>
        <div className="space-x-1">
          <p className="inline text-5xl font-header font-bold text-brownu-lightred">
            {this.props.homeScore !== null ? this.props.homeScore : "?"}
          </p>
          <p className="inline text-5xl font-header font-bold text-gray-600">
            :
          </p>
          <p className="inline text-5xl font-header font-bold text-brownu-taupe">
            {this.props.awayScore !== null ? this.props.awayScore : "?"}
          </p>
        </div>
      </div>
    );
  }
}