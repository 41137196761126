import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import ArticleBlock from "./ArticleBlock";
import toast from "react-hot-toast";
import GET_ARTICLES_QUERY from "../../queries/getArticles";
import Loading from "../button/Loading";
import { imageIdToURL } from "../../utils/LinkUtils";

function BlogList() {
  const [loaded, setloaded] = useState(6);
  const [maxed, setmaxed] = useState(false);
  const [loadingMoreArticles, setLoadingMoreArticles] = useState(false);

  let { loading, error, data, fetchMore } = useQuery(GET_ARTICLES_QUERY, {
    variables: { limit: 6, offset: 0 },
  });

  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  if (error) {
    toast.error(
      "Failed to establish a connection with the server. Please try again later.",
      { id: "noserverconnection", duration: Infinity }
    );
  }

  const loadMore = () => {
    if (!loadingMoreArticles) {
      setLoadingMoreArticles(true);
      setloaded(loaded + 3);
      fetchMore({
        variables: {
          limit: 3,
          offset: loaded,
        },
        // TODO: Update updateQuery as it's being deprecated
        updateQuery: (prev: any, { fetchMoreResult }) => {
          setLoadingMoreArticles(false);
          if (!fetchMoreResult) {
            setmaxed(true);
            return prev;
          }
          if (fetchMoreResult.article.length < 3) {
            setmaxed(true);
            return Object.assign({}, prev, {
              article: [...prev.article, ...fetchMoreResult.article],
            });
          }
          return Object.assign({}, prev, {
            article: [...prev.article, ...fetchMoreResult.article],
          });
        },
      });
    }
  };

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
          <div className="lg:flex lg:justify-between">
            {/* Main content */}
            <div
              className="lg:flex-grow"
              data-aos="fade-down"
              data-aos-delay="200"
            >
              {/* Section title */}
              <h4 className="text-4xl font-header font-bold text-white pb-4 mb-8 border-b border-gray-600">
                Latest
              </h4>

              {/* Articles container */}
              <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-6 md:gap-y-8 items-start">
                {!loading && !error
                  ? data.article.map((item: any) => {
                      return (
                        <ArticleBlock
                          title={item.title}
                          subtitle={item.subtitle}
                          link={`/news/${item.publish_date}/${item.slug}`}
                          author_name={
                            item.authors
                              ? `${item.authors.first_name} ${item.authors.last_name}`
                              : "Unknown Author"
                          }
                          profile_link="/"
                          date={new Date(item.publish_date).toLocaleDateString(
                            "en-us",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              timeZone: "UTC",
                            }
                          )}
                          tags={item.tags}
                          image_link={imageIdToURL(item?.photo?.id)}
                          author_image={item.profile?.avatar?.id}
                          key={"articlemain" + item.sort}
                        />
                      );
                    })
                  : [...Array(6)].map((_, i) => {
                      return (
                        <div className="flex flex-col h-full" key={"skeletonarticles"+i}>
                          <figure className="relative h-0 pb-9/16 bg-gray-600 animate-pulse mb-4"></figure>
                          <figure className="h-6 w-48 bg-gray-600 animate-pulse mb-4"></figure>
                          <figure className="h-4 w-full bg-gray-600 animate-pulse mb-2"></figure>
                          <figure className="h-4 w-full bg-gray-600 animate-pulse mb-2"></figure>
                          <figure className="h-4 w-24 bg-gray-600 animate-pulse mb-2"></figure>
                        </div>
                      );
                    })}
              </div>

              {/* Load more button */}
              {!maxed ? (
                <div
                  className="flex justify-center mt-12 md:mt-16"
                  onClick={() => loadMore()}
                >
                  <button
                    className={
                      "btn-sm  flex items-center relative " +
                      (loadingMoreArticles
                        ? "text-gray-300 bg-gray-600"
                        : "text-gray-300 hover:text-gray-50 bg-gray-800")
                    }
                  >
                    <span>See previous articles</span>
                    <svg
                      className="w-4 h-4 flex-shrink-0 ml-3"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current text-gray-500"
                        d="M14.7 9.3l-1.4-1.4L9 12.2V0H7v12.2L2.7 7.9 1.3 9.3 8 16z"
                      />
                    </svg>
                    <Loading visible={loadingMoreArticles} />
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogList;
