import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { imageIdToURL } from "../utils/LinkUtils";

const LEADERSHIP_QUERY = gql`
  query {
    board_member(filter: { position: { _contains: "president" } }) {
      id
      position
      position_alt
      avatar {
        id
      }
      profile {
        first_name
        last_name
        avatar {
          id
        }
      }
    }
    team_member {
      rank
      team {
        name
        game {
          name
        }
      }
      profile {
        first_name
        last_name
        avatar {
          id
        }
        board_member {
          avatar {
            id
          }
        }
      }
    }
    committee {
      id
      name
      committee_members(sort: ["position"]) {
        position
        position_alt
        board_member {
          avatar {
            id
          }
          profile {
            first_name
            last_name
            avatar {
              id
            }
          }
        }
      }
    }
  }
`;

const Leadership = () => {
  let { loading, error, data } = useQuery(LEADERSHIP_QUERY);

  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  if (error) {
    toast.error(
      "Failed to establish a connection with the server. Please try again later.",
      { id: "noserverconnection", duration: Infinity }
    );
  }

  return (
    <div className="bg-gray-900 text-gray-100 flex flex-col">
      <div className="relative">
        {/* Background image */}
        <div className="absolute inset-0 h-[40rem] md:h-[52rem] box-content">
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src={require("../components/assets/sayles-1.jpg")}
            alt="About"
          />
          <div
            className="absolute inset-0 bg-gradient-to-t from-white dark:from-gray-900 to-transparent via-transparent"
            aria-hidden="true"
          ></div>
        </div>
        <div className="relative h-[40rem] md:h-[56rem]">
          <img
            className="mx-auto w-32 md:w-64 pt-24 md:pt-40"
            src={require("../components/assets/logo_textless.png")}
            alt=""
          />
          <div className="text-5xl md:text-7xl font-header font-bold mx-auto text-center mt-8 md:mt-16 mb-8">
            2022-2023 Leadership
          </div>
          <p className="text-lg text-center md:text-xl font-body max-w-3xl mx-auto px-2">
            
          </p>
        </div>
      </div>
      <div className="container mx-auto px-8">
        <SectionHeader text="Executive Leadership" />
        <div className="flex flex-wrap mx-auto max-w-6xl">
          {!loading && !error
            ? getExecutiveLeadership(data).map((member: any) => {
                return (
                  <ProfileBlock
                    key={"execleader" + member.id}
                    first_name={member.profile.first_name}
                    last_name={member.profile.last_name}
                    position={member.position}
                    position_alt={member.position_alt}
                    avatar={
                      member.avatar?.id
                        ? member.avatar.id
                        : member.profile.avatar?.id
                    }
                  />
                );
              })
            : null}
        </div>
        <SectionHeader text="Team Captains" />
        <div className="flex flex-wrap">
          {!loading && !error
            ? getTeamCaptains(data).map((teammember: any) => {
                return (
                  <ProfileBlock
                    key={
                      "teamcaptain" +
                      teammember.profile.first_name +
                      teammember.profile.last_name
                    }
                    first_name={teammember.profile.first_name}
                    last_name={teammember.profile.last_name}
                    position={"team_captain"}
                    position_alt={teammember.team.game.name}
                    avatar={
                      teammember.profile.board_member[0]?.avatar?.id
                        ? teammember.profile.board_member[0].avatar.id
                        : teammember.profile.avatar?.id
                    }
                    class="basis-1/4"
                  />
                );
              })
            : null}
        </div>
        <SectionHeader text="Board Committees" />
        <div>
          {" "}
          {/* Create a square grid to display committee members */}
          {!loading && !error
            ? getCommitteeMembers(data).map((committee) => {
                return (
                  <Committee
                    key={"committee" + committee.id}
                    committee={committee}
                  />
                );
              })
            : null}
        </div>
        <SectionHeader text="Staff and Advisors" />
        <div>
          {!loading && !error
            ? getOtherMembers(data).map((committee) => {
                return (
                  <Committee
                    key={"committee" + committee.id}
                    committee={committee}
                  />
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

type profileBlockProps = {
  first_name: string;
  last_name: string;
  avatar?: string;
  position: string;
  position_alt?: string;
  class?: string;
  small?: boolean;
};

const ProfileBlock = (props: profileBlockProps) => {
  return (
    <div className={props.class ? props.class : "basis-1/3"}>
      <div className="m-4">
        {/* Display user avatar */}
        <div className="flex flex-col">
          <div className="">
            <img
              // TODO: Replace default avatar with something more reasonable
              // Suggest adding an optional second parameter to imageIdToURL
              // that takes in an image and uses it as the default
              src={imageIdToURL(props.avatar)}
              className="object-cover aspect-square"
              alt="avatar"
            />
          </div>
          <div className="flex flex-col my-4">
            <div
              className={
                "font-bold font-header " +
                (props.small ? "text-sm md:text-lg" : "md:text-2xl text-lg")
              }
            >
              {props.first_name} {props.last_name}
            </div>
            <div
              className={
                "font-body tracking-wide text-gray-400 font-medium" +
                (props.small ? "md:text-sm text-xs" : "md:text-md text-base")
              }
            >
              {PositionMap[props.position]
                ? PositionMap[props.position]
                : props.position}
              {props.position_alt ? " - " + props.position_alt : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SectionHeader = (props: { text: string }) => {
  return (
    <div className="font-bold font-header text-3xl md:text-5xl text-center text-gray-100 uppercase tracking-wider mb-6 mt-8">
      {props.text}
    </div>
  );
};

const Committee = (props: { committee: any }) => {
  if (props.committee.committee_members.length === 0) {
    return null;
  }
  return (
    <div>
      <div className="font-header tracking-wide text-2xl text-gray-100 mt-12 mb-4">
        {props.committee.name}
      </div>
      <div className="grid grid-cols-5">
        {props.committee.committee_members.map((member: any) => {
          return (
            <ProfileBlock
              key={
                "committeemember_primary" +
                member.board_member.profile.first_name +
                member.board_member.profile.last_name +
                props.committee.name
              }
              first_name={member.board_member.profile.first_name}
              last_name={member.board_member.profile.last_name}
              position={
                member.position_alt ? member.position_alt : member.position
              }
              avatar={member.board_member.avatar?.id ? member.board_member.avatar.id : member.board_member.profile?.avatar?.id}
              class=""
              small={true}
            />
          );
        })}
      </div>
    </div>
  );
};

const getExecutiveLeadership = (data: any) => {
  const president = data.board_member.filter((member: any) => {
    return member.position === "president";
  });
  const vice_president = data.board_member.filter((member: any) => {
    return member.position === "vice_president";
  });
  return [...president, ...vice_president];
};

const getTeamCaptains = (data: any) => {
  const team_captains = data.team_member
    .filter((member: any) => {
      return member.rank.includes("captain");
    })
    .sort((a: any, b: any) => {
      a = a.team.game.name.toLowerCase();
      b = b.team.game.name.toLowerCase();
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
  return [...team_captains];
};

const getCommitteeMembers = (data: any) => {
  // sort data.committee by committee name
  const committee_members = data.committee
    .filter((obj: any) => {
      return obj.name.toLowerCase().includes("committee");
    })
    .sort((a: any, b: any) => {
      a = a.name.toLowerCase();
      b = b.name.toLowerCase();
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
  return [...committee_members];
};

const getOtherMembers = (data: any) => {
  // sort data.committee by committee name
  const other_members = data.committee
    .filter((obj: any) => {
      return !obj.name.toLowerCase().includes("committee");
    })
    .sort((a: any, b: any) => {
      a = a.name.toLowerCase();
      b = b.name.toLowerCase();
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
  return [...other_members];
};

type positionType = {
  [key: string]: string;
};

const PositionMap: positionType = {
  president: "President",
  vice_president: "Vice President",
  team_captain: "Team Captain",
  committee_head: "Committee Head",
  committee_member: "Committee Member",
};

export default Leadership;
