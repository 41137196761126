import Button from "../../components/Button";
import ScoreHero from "../../components/ScoreHero";
import "./divider.css";
import Person from "./soccer.png";
import discordlogo from "../../components/assets/discord_logo_white.svg";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import { RelatedArticles } from "../NewsText";
import { useState } from "react";
import ReactHelmet from "../../components/react-helmet/react-helmet";

export default function Home() {
  const [newsShow, setNewsShow] = useState<number>(-1);
  return (
    <div className="bg-gray-900 min-h-screen overflow-x-hidden">
      {/* TODO: Remove overflow-x-hidden and find what is causing it */}
      <ReactHelmet title="Home page" description="" />
      <div className="container mx-auto pt-4 sm:pt-16 mb-0 sm:px-8 px-4 z-0 relative">
        <div className="mt-24">
          <div className="relative">
            <div className="lg:w-3/4 xl:w-1/2">
              <h2 className="md:text-6xl sm:text-5xl text-3xl !leading-snug text-white font-header font-bold mb-6">
                Brown University's home for casual and competitive gaming.
              </h2>
              <p className="text-gray-200 font-body sm:text-lg mb-6">
                Thousands of students at Brown and RISD — from the occasional
                player to the competitive gamer — are avid members of Brown
                Esports.
              </p>
              <Button background={true} text="Join now" />
              <Button background={false} text="Learn more" />
            </div>
            <div className="hidden xl:block">
              <img
                src={Person}
                alt="Person"
                className="absolute right-0 top-0 -mt-16 -z-10"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="sm:h-36 h-20 from-transparent to-gray-800 bg-gradient-to-b z-10 relative"></div>
      <ScoreHero />
      <div className="divider bg-gray-900 h-72 mt-[-80px]"></div>
      <div className="max-w-4xl mx-auto px-4 text-center text-gray-200">
        <h2 className="text-white font-header font-bold text-4xl mb-4">
          What is Brown Esports?
        </h2>
        <p className="text-lg font-body">
          Since 2015, Brown Esports has created a welcoming home for both casual
          and competitive gamers. With nearly a hundred students on competitive
          teams, and thousands as part of casual communities, Brown Esports is
          one of the largest student organizations at Brown University, and the
          largest esports and gaming organization in the Ivy League.
        </p>
      </div>
      <Divider />
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex text-white mb-8 items-center">
          <h2 className="font-header font-bold text-4xl sm:text-center flex-1">
            Latest News
          </h2>
          <button
            className="sm:hidden"
            onClick={() => setNewsShow(newsShow < 1 ? 3 : newsShow - 1)}
          >
            <Icon path={mdiChevronLeft} size={1.3} />
          </button>
          <button
            className="sm:hidden"
            onClick={() =>
              setNewsShow(newsShow === -1 ? 1 : (newsShow + 1) % 4)
            }
          >
            <Icon path={mdiChevronRight} size={1.3} />
          </button>
        </div>
        <div id="newsreel">
          <RelatedArticles onlyshow={newsShow} />
        </div>
      </div>
      <Divider />
      <div className="max-w-4xl mx-auto">
        <h2 className="text-white font-header font-bold text-4xl mb-4 text-center">
          Brown Esports Partners
        </h2>
        <div className="flex gap-8 mx-auto lg:flex-row flex-col lg:my-0 my-8">
          <div className="flex-1 items-center">
            <img
              className="h-24 mx-auto"
              src={require("../../components/assets/kingston-fury.png")}
              alt="Kingston Fury Logo"
            />
          </div>
          <div className="flex-1 items-center">
            <img
              className="h-24 mx-auto"
              src={require("../../components/assets/msi.png")}
              alt="MSI Logo"
            />
          </div>
        </div>
      </div>
      <Divider />
      {/* Discord Banner */}
      <div className="bg-[#5865F2] max-w-6xl px-12 py-16 mx-auto flex lg:flex-row flex-col">
        <div className="mx-auto lg:mr-8">
          <img className="h-full" src={discordlogo} alt="Discord Logo" />
        </div>
        <div className="flex-grow lg:my-0 mt-6 mb-12 lg:mx-0 mx-auto lg:text-left text-center">
          <h2 className="text-white font-header font-bold text-3xl sm:text-4xl mb-4 lg:mb-0">
            Join our casual community!
          </h2>
          <p className="text-gray-100 font-body text-lg">
            Discord is a place for you to connect with other Brown students.
          </p>
        </div>
        <div className="self-center">
          {/* TODO: add tracking to link */}
          <a
            className="bg-white text-[#5865F2] px-6 py-2 text-lg font-semibold rounded-md shadow-md"
            href="https://discord.gg/brownesports"
          >
            <span>Join Discord Server</span>
            <Icon
              path={mdiChevronRight}
              size={1}
              className="inline-block mt-[-2px] ml-1 -mr-2"
            />
          </a>
        </div>
      </div>
      <Divider blank={true} />
    </div>
  );
}

const Divider = (props: { blank?: boolean }) => {
  if (props.blank) {
    return <div className="h-16"></div>;
  }
  return (
    <div>
      <hr className="my-16 max-w-6xl mx-auto border-gray-750"></hr>
    </div>
  );
};
