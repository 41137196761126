import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

type ControlledLinkProps = {
  href: string;
  className: string;
  navlink?: boolean;
  activeClassName?: string;
};

export function formatPublicLink(url: string) {
  if (
    url &&
    !url.includes("http://") &&
    !url.includes("https://") &&
    url.charAt(0) === "/"
  ) {
    return process.env.PUBLIC_URL + url;
  } else {
    return url;
  }
}

export function isExternalLink(url: string) {
  if (
    url &&
    !url.includes("http://") &&
    !url.includes("https://") &&
    url.charAt(0) === "/"
  ) {
    return false;
  } else if (url === "") {
    return null;
  }
  return true;
}

export function imageIdToURL(
  id: string | null | undefined,
  default_image?: string | File
) {
  if (id) {
    return `${process.env.REACT_APP_BACKEND_URL}/assets/${id}`;
  }
  // return default image if ID is null or undefined
  // only if default_image is defined
  if (default_image) {
    return default_image;
  }
  // fallback to default image
  return require("../components/assets/bg11.jpg");
}

export class ControlledLink extends Component<ControlledLinkProps> {
  render() {
    let ext = isExternalLink(this.props.href);
    if (ext) {
      return (
        <a href={this.props.href} className={this.props.className}>
          {this.props.children}
        </a>
      );
    } else if (ext === null) {
      <div className={this.props.className}>{this.props.children}</div>;
    } else if (this.props.navlink) {
      return (
        <NavLink
          end
          to={this.props.href}
          className={({ isActive }) =>
            isActive && this.props.activeClassName
              ? this.props.activeClassName
              : this.props.className
          }
        >
          {this.props.children}
        </NavLink>
      );
    }
    return (
      <Link to={this.props.href} className={this.props.className}>
        {this.props.children}
      </Link>
    );
  }
}
