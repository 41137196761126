// @ts-ignore
import BEST from "../assets/logo_textless.png";
import { ControlledLink } from "../../utils/LinkUtils";
// @ts-ignore
import discord from "../assets/discord_partner.svg";
import right_arrow from "./right_arrow.svg";
import smashball from "./smashball.svg";

const footerLinks = [
  {
    title: "Organization",
    links: [
      {
        name: "About",
        href: "/about",
      },
      {
        name: "Leadership",
        href: "/leadership",
      },
      {
        name: "Support",
        href: "/support",
      },
      {
        name: "Media and Press",
        href: "/press",
      },
      {
        name: "Filler Item",
        href: "https://google.com",
      },
    ],
  },
  {
    title: "Competitive",
    links: [
      {
        name: "Teams",
        href: "/teams",
      },
      {
        name: "Matches",
        href: "/matches",
      },
      {
        name: "Join a Team",
        href: "/teams/apply",
      },
      {
        name: "Create a Team",
        href: "/teams/create",
      },
      {
        name: "Players",
        href: "/players",
      },
    ],
  },
  {
    title: "Casual",
    links: [
      {
        name: "Weekly Meetings",
        href: "/casual/meetings",
      },
      {
        name: "Event Calendar",
        href: "/events",
      },
      {
        name: "Support",
        href: "/support",
      },
      {
        name: "Media and Press",
        href: "/press",
      },
      {
        name: "Filler Item",
        href: "https://google.com",
      },
    ],
  },
  {
    title: "Follow Us!",
    links: [
      {
        name: "Discord",
        href: "https://discord.gg/brownesports",
      },
      {
        name: "Twitch",
        href: "https://twitch.tv/brownesports",
      },
      {
        name: "Twitter",
        href: "https://twitter.com/brownesports",
      },
      {
        name: "YouTube",
        href: "/press",
      },
      {
        name: "Facebook",
        href: "https://facebook.com/brownesports",
      },
    ],
    //links: {
    //    Discord: "https://discord.gg/brownesports",
    //    Twitch: "https://twitch.tv/brownesports",
    //    Twitter: "https://twitter.com/brownesports",
    //    YouTube: "https://www.youtube.com/channel/UCJ8PeDzMosS60XEjHeVjIhQ",
    //    Facebook: "https://facebook.com",
    //},
  },
];

function Footer() {
  return (
    <footer className="text-white bg-gray-850">
      <div className="container mx-auto px-8 xl:pt-10 pt-16">
        <div className="grid xl:grid-cols-12 grid-cols-4 gap-8 pb-8">
          <div className="xl:col-span-3 md:col-span-2 col-span-4 flex xl:border-r xl:border-gray-700 order-1">
            <img
              src={BEST}
              className="w-24 object-scale-down flex-none xl:-mt-12 -mt-8"
              alt="BEST Logo"
            />
            <div className="pl-6">
              <h1 className="text-2xl font-header font-bold flex-1">Brown Esports</h1>
              <p className="font-body text-sm font-medium tracking-wide text-gray-400">
                75 Waterman St
              </p>
              <p className="font-body text-sm font-medium tracking-wide text-gray-400">
                Providence, RI 02912
              </p>
              <div className="mt-2 font-body text-xs font-medium tracking-wide text-gray-500 hidden 2xl:block">
                <span>About</span>
                {"  ·  "}
                <span>Privacy Policy</span>
              </div>
            </div>
          </div>
          <div className="grid grid-flow-row md:grid-cols-4 grid-cols-2 gap-8 xl:col-span-7 col-span-4 xl:pl-8 xl:order-2 order-3">
            {footerLinks.map((col, index) => {
              return (
                <div className="col-span-1" key={"footer" + index}>
                  <h1 className="font-body font-medium text-gray-200 tracking-wide mb-1">
                    {col.title}
                  </h1>
                  <ul className="font-body font-medium text-sm text-gray-400 tracking-wide">
                    {col.links.map((link, index_link) => {
                      return (
                        <ControlledLink
                          key={
                            "footerlink" +
                            index.toString() +
                            index_link.toString()
                          }
                          href={link.href}
                          className={""}
                        >
                          <li className="mb-1">{link.name}</li>
                        </ControlledLink>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          <div className="md:col-span-2 xl:order-3 order-2 hidden md:block">
            <img
              className="xl:w-36 md:w-32 w-24 xl:mt-0 md:-mt-8 float-right"
              src={discord}
              alt="Discord Partner Logo"
            />
          </div>
        </div>
        <div className="py-4 hidden lg:block">
          <div className="flex">
            <p className="flex-shrink-0 font-body font-medium text-gray-400 xl:mr-16 mr-8">
              Competitive teams:
            </p>
            <div className="flex flex-1 flex-wrap h-6 overflow-hidden">
              <div className="flex-auto mx-2">
                <img
                  className="inline -mt-1 mr-2"
                  src={smashball}
                  alt="Game Icon"
                />
                <p className="inline font-body font-medium tracking-wide text-gray-400">
                  League of Legends
                </p>
              </div>
              <div className="flex-auto mx-2">
                <img
                  className="inline -mt-1 mr-2"
                  src={smashball}
                  alt="Game Icon"
                />
                <p className="inline font-body font-medium tracking-wide text-gray-400">
                  Super Smash Bros. Ultimate
                </p>
              </div>
              <div className="flex-auto mx-2">
                <img
                  className="inline -mt-1 mr-2"
                  src={smashball}
                  alt="Game Icon"
                />
                <p className="inline font-body font-medium tracking-wide text-gray-400">
                  Rainbow 6 Siege
                </p>
              </div>
              <div className="flex-auto mx-2">
                <img
                  className="inline -mt-1 mr-2"
                  src={smashball}
                  alt="Game Icon"
                />
                <p className="inline font-body font-medium tracking-wide text-gray-400">
                  VALORANT
                </p>
              </div>
              <div className="flex-auto mx-2">
                <img
                  className="inline -mt-1 mr-2"
                  src={smashball}
                  alt="Game Icon"
                />
                <p className="inline font-body font-medium tracking-wide text-gray-400">
                  CS:GO
                </p>
              </div>
              <div className="flex-auto mx-2">
                <img
                  className="inline -mt-1 mr-2"
                  src={smashball}
                  alt="Game Icon"
                />
                <p className="inline font-body font-medium tracking-wide text-gray-400">
                  Call of Duty
                </p>
              </div>
              <div className="flex-auto mx-2">
                <img
                  className="inline -mt-1 mr-2"
                  src={smashball}
                  alt="Game Icon"
                />
                <p className="inline font-body font-medium tracking-wide text-gray-400">
                  Hearthstone
                </p>
              </div>
            </div>
            <div className="flex-shrink-0 xl:ml-16 ml-8">
              <p className="inline font-body font-medium tracking-wide text-gray-400 text-right">
                All teams{" "}
              </p>
              <img
                className="inline -mt-1"
                src={right_arrow}
                alt="Right arrow"
              />
            </div>
          </div>
        </div>
        <div className="border-t border-gray-600">
          <p className="font-body font-medium text-gray-500 text-xs md:py-6 py-4">
            Copyright © 2021 Brown Esports. Made with love by students at Brown
            University.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
