import { Helmet } from "react-helmet";
import { Component, ReactNode } from "react";
import { mdiThumbsUpDown } from "@mdi/js";

type Props = {
  title?: string;
  description?: string;
  image?: string;
};

export default class ReactHelmet extends Component<Props, {}> {
  render(): ReactNode {
    return (
      <Helmet>
        <title>{this.props.title ? this.props.title : "Brown Esports"}</title>
        <meta
          name="description"
          content={
            this.props.description
              ? this.props.description
              : "Brown Esports is Brown Univeristy's home for both casual and competitive gaming."
          }
        />
        <meta
          property="og:title"
          content={this.props.title ? this.props.title : "Brown Esports"}
        />
        <meta
          property="og:description"
          content={
            this.props.description
              ? this.props.description
              : "Brown Esports is Brown Univeristy's home for both casual and competitive gaming."
          }
        />
        {this.props.image ? (
          <meta property="og:image" content={this.props.image} />
        ) : null}
      </Helmet>
    );
  }
}
