import React from "react";
import { Link } from "react-router-dom";
import { TagType } from "../../types";

function Tag(props: TagType) {
  return (
    <li className="m-1">
      <Link
        to="#"
        className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full transition duration-150 ease-in-out hover:bg-opacity-80 hover:saturate-150"
        style={{ backgroundColor: props.color }}
      >
        {props.text}
      </Link>
    </li>
  );
}

export default Tag;
