import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import { TagType } from "../../types";
import { imageIdToURL } from "../../utils/LinkUtils";
import Tag from "./Tag";

const GET_ARTICLE_QUERY = gql`
  query GetArticles {
    article(sort: "-publish_date", limit: 1) {
      title
      subtitle
      publish_date
      slug
      photo {
        id
      }
      tags {
        tags_id {
          text
          color
        }
      }
      authors {
        first_name
        last_name
        avatar {
          id
        }
      }
    }
  }
`;

function BlogHero() {
  let { loading, error, data } = useQuery(GET_ARTICLE_QUERY);
  return (
    <section className="relative">
      {/* Background image */}
      <div className="absolute inset-0 h-[32rem] pt-16 box-content">
        <img
          className="absolute inset-0 w-full h-full object-cover opacity-25"
          src={require("../assets/hero-bg.jpg")}
          alt="About"
        />
        <div
          className="absolute inset-0 bg-gradient-to-t from-white dark:from-gray-900"
          aria-hidden="true"
        ></div>
      </div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-16">
          {/* Featured article */}
          <div className="" data-aos="fade-down">
            <article className="max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center">
              <Link
                to={
                  !loading && !error
                    ? `/news/${data.article[0].publish_date}/${data.article[0].slug}`
                    : "/"
                }
                className="relative block group"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <div
                  className="absolute inset-0 bg-gray-800 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-8 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none"
                  aria-hidden="true"
                ></div>
                <figure className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out">
                  {!loading && !error ? (
                    <img
                      className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                      src={imageIdToURL(data.article[0]?.photo?.id)}
                      width="540"
                      height="303"
                      alt="Blog post 01"
                    />
                  ) : (
                    <div
                      className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out bg-gray-600 animate-pulse"
                      style={{ width: "540px", height: "303px" }}
                    ></div>
                  )}
                </figure>
              </Link>
              <div data-aos="fade-left" data-aos-delay="200">
                <header>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      {!loading && !error && data.article[0]
                        ? data.article[0].tags
                            .map((tag: any) => tag.tags_id)
                            .map((tag: TagType, i: number) => {
                              return (
                                <Tag
                                  color={tag.color}
                                  text={tag.text}
                                  key={"herotags" + i}
                                />
                              );
                            })
                        : null}
                    </ul>
                  </div>
                  {!loading && !error && data.article[0] ? (
                    <h3 className="h3 text-2xl lg:text-3xl mb-2">
                      <Link
                        to={
                          !loading && !error
                            ? `/news/${data.article[0].publish_date}/${data.article[0].slug}`
                            : "/"
                        }
                        className="text-4xl font-header font-bold mb-4 text-white leading-tight"
                      >
                        {data.article[0].title}
                      </Link>
                    </h3>
                  ) : (
                    <div className="w-full mb-4 bg-gray-600 animate-pulse h-16"></div>
                  )}
                </header>
                {!loading && !error && data.article[0] ? (
                  <p className="text-lg text-gray-400 flex-grow">
                    {data.article[0].subtitle}
                  </p>
                ) : (
                  <>
                    <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                    <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                    <div className="w-1/3 bg-gray-600 h-6 animate-pulse"></div>
                  </>
                )}
                <footer className="flex items-center mt-4">
                  <Link
                    to={
                      !loading && !error
                        ? `/news/${data.article[0].publish_date}/${data.article[0].slug}`
                        : "/"
                    }
                  >
                    {" "}
                    {/*TODO: Change to link to profile*/}
                    <img
                      className="rounded-full flex-shrink-0 mr-4"
                      src={
                        // if the page is not loading and article has author
                        !loading && !error && data.article[0]?.authors
                          ? // if the author has an avatar
                            data.article[0]?.authors?.avatar
                            ? // display author avatar
                              imageIdToURL(data.article[0].authors.avatar.id)
                            : // set profile picture to a bubble with their first and last initial
                              `https://ui-avatars.com/api/?name=${data.article[0].authors.first_name} ${data.article[0].authors.last_name}&color=cccccc&background=333333`
                          : // else just return a bubble with "UA"
                            `https://ui-avatars.com/api/?name=Brown+Esports&color=cccccc&background=333333`
                      }
                      width="40"
                      height="40"
                      alt="Author 04"
                    />
                  </Link>
                  <div>
                    <Link
                      to="#"
                      className="font-medium text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      {!loading && !error && data?.article[0]?.authors
                        ? `${data.article[0].authors.first_name} ${data.article[0].authors.last_name}`
                        : "Brown Esports"}
                    </Link>
                    <span className="text-gray-500"> · </span>
                    <span className="text-gray-400">
                      {!loading && !error && data.article[0]
                        ? new Date(
                            data.article[0].publish_date
                          ).toLocaleDateString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            timeZone: "UTC",
                          })
                        : null}
                    </span>
                  </div>
                </footer>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogHero;
