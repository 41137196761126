import { mdiChevronRight } from '@mdi/js'
import Icon from '@mdi/react'
import React, { Component } from 'react'

type ButtonProps = {
  text: string,
  background: boolean
}

export class Button extends Component<ButtonProps> {
  render() {
    return (
      <div className={"inline-block px-4 py-1 rounded-full tracking-wide text-gray-200 font-body font-medium text-sm sm:text-base " + (this.props.background ? "bg-brownu-lightred" : "")}>
        <span>{this.props.text}</span>
        <Icon path={mdiChevronRight} size={1} className="inline-block mt-[-1px] ml-1 -mr-2" />
      </div>
    )
  }
}

export default Button
