import { useEffect, useState } from "react";
import axios from "axios";
import { getRecurringEvents, getSingleEvents, RecurringEvent } from "../../utils/Events";

const available_pcs = 7;

const BookingDisplay = () => {
  const [now, setNow] = useState(new Date());
  const [event, setEvent] = useState({
    title: "",
    description: "",
    start: new Date(),
    end: new Date(),
    today: false,
    now: false,
    closed: false,
    withinOneHour: false,
  });
  const [eventsToday, setEventsToday] = useState([]);
  const [roomState, setRoomState] = useState([]);
  useEffect(() => {
    const pcData = () => {
      console.log(
        "https://brownesports.bebooking.enes.tech/offices/1/map/?paginate=false&from_date=" +
          new Date().toISOString() +
          "&to_date=" +
          new Date(
            new Date().setMinutes(new Date().getMinutes() + 30)
          ).toISOString()
      );
      axios
        .get(
          "https://brownesports.bebooking.enes.tech/offices/1/map/?paginate=false" +
            senetDateString()
        )
        .then((res) => {
          console.log(res.data);
          setRoomState(res.data);
        });
    };
    const updateState = () => {
      // let now = new Date(); // Hacky - why when accessing "now" in the useEffect hook, it doesn't follow state?
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/calendar`)
        .then((res) => {
          let events = getSingleEvents(res.data)

          let recurringEvents = getRecurringEvents(res.data)

          let recurringEventClass: any = [];

          recurringEvents.forEach((item: any) => {
            recurringEventClass.push(new RecurringEvent(item));
          });

          let updatedRecurringDates = recurringEventClass.map((item: any) => {
            return item.getNearestOccurrence();
          });

          // combine recurring events with non-recurring events
          events = [...events, ...updatedRecurringDates];

          // refilter events to remove end dates that have already passed
          events = events.filter((item: any) => {
            // filter out events that have already passed
            // Does not support full-day events!
            // Exclude all events with recurrence (handle them later)
            return new Date(item.end.dateTime) > now;
          });

          // sort events by start time
          events = events.sort((a: any, b: any) => {
            return (
              new Date(a.start.dateTime).getTime() -
              new Date(b.start.dateTime).getTime()
            );
          });

          // Finally! Get on to proper processing
          // Check if event is happening now

          console.log(events); // TODO: remove console.log
          // get the first one
          const event = events[0];
          // get the start time
          const start = new Date(event.start.dateTime);
          // get the end time
          const end = new Date(event.end.dateTime);
          // get the title
          const title = event.summary;
          // get the description
          const description = event.description;
          // check if event is today
          const today = start.getDate() === now.getDate();
          // check if event is now
          const isNow =
            start.getTime() <= now.getTime() && end.getTime() >= now.getTime();

          console.log(event);

          console.log(start.getTime());
          console.log(now.getTime());
          console.log(end.getTime());

          setEvent({
            title: title,
            description: description,
            start: start,
            end: end,
            today: today,
            now: isNow,
            closed: isNow && title === "Closed",
            withinOneHour: start.getTime() - now.getTime() <= 3600000,
          });

          // get events today
          let eventsToday = events
            .filter((item: any) => {
              return (
                new Date(item.start.dateTime).getDate() === now.getDate() &&
                new Date(item.end.dateTime).getDate() === now.getDate()
              );
            })
            .map((item: any) => {
              return {
                title: item.summary,
                description: item.description,
                start: new Date(item.start.dateTime),
                end: new Date(item.end.dateTime),
              };
            });

          setEventsToday(eventsToday);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    updateState();
    pcData();
    const refresh = setInterval(() => {
      // setNow if minute has changed
      if (now.getMinutes() !== new Date().getMinutes()) {
        setNow(new Date());
      }
    }, 1000);

    const stateUpdate = setInterval(() => {
      updateState();
    }, 15000);

    const roomUpdate = setInterval(() => {
      pcData();
    }, 60 * 1000);

    return () => {
      clearInterval(refresh);
      clearInterval(stateUpdate);
      clearInterval(roomUpdate);
    };
  }, [now]);

  return (
    <div className="relative h-screen w-screen cursor-none">
      <img
        className="absolute h-screen w-screen object-cover object-top"
        src={require("../../components/assets/sunset.jpg")}
        alt="Providence sunset"
      />
      <div className="absolute h-screen w-screen bg-black opacity-60"></div>
      <div
        className={
          "absolute w-screen h-screen border-8 text-white p-8 md:p-16 flex " +
          (event.closed
            ? "border-purple-600"
            : event.now
            ? "border-red-600"
            : event.withinOneHour
            ? "border-yellow-500"
            : "border-green-500")
        }
      >
        <section className="flex-1">
          <div className="flex">
            <div className="font-bold font-header text-4xl md:text-5xl tracking-wide">
              {now
                .toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })
                .slice(0, -3)}
            </div>
            <div
              className="font-body font-medium text-base md:text-lg ml-2 place-self-start tracking-wide"
              style={{ lineHeight: 1 }}
            >
              {now
                .toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })
                .slice(-2)}
            </div>
          </div>
          <div className="font-body text-lg tracking-wide">
            {now.toLocaleDateString("en-US", {
              weekday: "long",
              month: "long",
              day: "numeric",
            }) + ordinal(now.getDate())}
          </div>
          <div
            className={
              "font-header text-6xl md:text-9xl font-bold mt-24 " +
              (event.closed
                ? "text-purple-500"
                : event.now
                ? "text-red-600"
                : event.withinOneHour
                ? "text-yellow-500"
                : "text-green-500")
            }
          >
            {event.closed ? "Closed 🌠" : event.now ? event.title : "Available"}
          </div>
          {event.now ? (
            <Subtext>
              The lounge {event.closed ? "opens" : "will be available"} at{" "}
              {event.end.toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
              })}
              .
            </Subtext>
          ) : null}
          {event.withinOneHour && event.title !== "Closed" && !event.now ? (
            <Subtext>
              The lounge is reserved in{" "}
              {Math.ceil(
                (event.start.getTime() - new Date().getTime()) / 1000 / 60
              )}{" "}
              minute
              {Math.ceil(
                (event.start.getTime() - new Date().getTime()) / 1000 / 60
              ) !== 1
                ? "s"
                : null}{" "}
              from{" "}
              {event.start.toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
              })}{" "}
              to{" "}
              {event.end.toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
              })}
              .
            </Subtext>
          ) : null}
          {event.title === "Closed" && !event.withinOneHour ? (
            <Subtext>
              There are no more reservations scheduled for today.
            </Subtext>
          ) : null}
          {event.withinOneHour && event.title === "Closed" && !event.closed ? ( // TODO: make this show if an event is currently happening
            <Subtext>
              The lounge is closing in{" "}
              {Math.floor(
                (event.start.getTime() - new Date().getTime()) / 1000 / 60
              )}{" "}
              minutes (
              {event.start.toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
              })}
              ).
            </Subtext>
          ) : null}
          {!event.withinOneHour && !event.closed ? ( // TODO: make this show if an event is currently happening
            <Subtext>
              The lounge is available until{" "}
              {event.start.toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
              })}
              .
            </Subtext>
          ) : null}
        </section>
        <section className="absolute bottom-0 left-0 p-8 text-gray-500 opacity-75 text-2xl font-header font-bold">
          <span>
            {roomState.filter((pc: any) => {
              return pc.is_available;
            }).length +
            available_pcs -
            roomState.length
              ? roomState.filter((pc: any) => {
                  return pc.is_available;
                }).length +
                available_pcs -
                roomState.length
              : 0}
            /{available_pcs}
          </span>
          <span className="font-normal"> available</span>
        </section>
        <section>
          {eventsToday.map((event: any) => {
            return (
              <div className="bg-black px-8 py-4 mb-4 w-80 rounded-md">
                <div className="font-header text-lg tracking-wide">
                  {event.title}
                </div>
                <div className="text-sm">
                  {event.start.toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                  {" - "}
                  {event.end.toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </div>
              </div>
            );
          })}
        </section>
      </div>

      <div className="absolute bottom-0 right-0 m-8 flex flex-col items-center">
        <img
          className="h-20 w-20"
          src={require("../../components/assets/qr_booking.png")}
          alt="Booking QR code"
        />
      </div>
    </div>
  );
};

const ordinal = function (n: number) {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
};

const Subtext = (props: any) => {
  return (
    <div className="font-body text-gray-200 text-2xl md:text-3xl tracking-wide font-medium mt-4">
      {props.children}
    </div>
  );
};

const senetDateString = () => {
  const now = new Date();
  const rounded_now = new Date(
    now.setHours(now.getHours(), now.getMinutes(), 0, 0)
  );
  const rounded_later = new Date(
    rounded_now.setMinutes(rounded_now.getMinutes() + 30)
  );
  return (
    "&from_date=" +
    rounded_now.toISOString() +
    "&to_date=" +
    rounded_later.toISOString()
  );
};

export default BookingDisplay;
