import { gql, useQuery } from "@apollo/client";
import {
  mdiInstagram,
  mdiLinkedin,
  mdiShield,
  mdiTwitch,
  mdiTwitter,
  mdiYoutube,
} from "@mdi/js";
import Icon from "@mdi/react";
import { useParams } from "react-router-dom";
import { imageIdToURL } from "../utils/LinkUtils";

var md = require("markdown-it")();

const GET_PROFILE_QUERY = gql`
  query getProfileByID($id: ID!) {
    profile_by_id(id: $id) {
      id
      first_name
      last_name
      avatar {
        id
      }
      bio
      twitch
      twitter
      youtube
      instagram
      linkedin
      team_member {
        id
        username
        rank
        position
        team {
          id
          name
          slug
          suffix
          game {
            id
            name
            icon {
              id
            }
          }
        }
      }
      board_member {
        position
        committee_members {
          id
          position
          position_alt
          committee {
            id
            name
          }
        }
      }
    }
  }
`;

const Profile = () => {
  const { id } = useParams();
  let { loading, error, data } = useQuery(GET_PROFILE_QUERY, {
    variables: {
      id,
    },
  });
  if (data) console.log(data);
  return (
    <div className="relative bg-gray-900">
      {/* Background image */}
      <div className="absolute inset-0 h-[32rem] pt-16 box-content">
        <img
          className="absolute inset-0 w-full h-full object-cover opacity-25"
          src={require("../components/assets/hero-bg.jpg")}
          alt="About"
        />
        {/* <div
          className="absolute inset-0" // bg-gradient-to-t from-white dark:from-gray-900
          aria-hidden="true"
        ></div> */}
      </div>
      <div className="relative">
        <div className="h-[20rem]"></div>
        <div className="h-[16rem] grid grid-cols-7 mb-8">
          {/* Overcompensate by 4rem for pt-16, mb-8 for extra 8 units of padding */}
          <div className="col-span-2"></div>
          <div
            className="col-span-1 place-self-end"
            style={{
              height: 0,
              width: 0,
              borderRight: "8rem solid rgb(82 82 82 / 0.6)",
              borderBottom: "8rem solid rgb(82 82 82 / 0.6)",
              borderTop: "8rem solid transparent",
              borderLeft: "8rem solid transparent",
            }}
          ></div>
          <div className="relative col-span-4 bg-gray-600 bg-opacity-60 text-white px-4 py-8">
            <div className="absolute -left-96 -top-12 w-80 h-80 rounded-full border-white border-4 overflow-hidden">
              {!loading && !error ? (
                <img
                  className="w-full h-full object-cover border-gray-400 border-opacity-25 border-[10px] rounded-full"
                  src={imageIdToURL(
                    data.profile_by_id?.avatar?.id,
                    `https://ui-avatars.com/api/?name=${data.profile_by_id.first_name} ${data.profile_by_id.last_name}&color=cccccc&background=333333`
                  )}
                  alt="About"
                />
              ) : null}
            </div>
            {!loading && !error ? (
              <div>
                <div className="mb-4">
                  <div className="text-white font-header font-bold text-lg tracking-widest">
                    {getHighestPosition(data?.profile_by_id)}
                  </div>
                </div>
                <div className="text-white font-header font-bold text-5xl">{`${data?.profile_by_id?.first_name} ${data?.profile_by_id?.last_name}`}</div>
                <div className="flex text-white gap-3 mt-4">
                  {data?.profile_by_id?.twitch ? (<a
                    href={`https://www.twitch.tv/${data?.profile_by_id?.twitch}`}
                  >
                    <Icon
                      path={mdiTwitch}
                      size={1}
                      className="hover:text-[#9146FF] transition-colors duration-150"
                    />
                  </a>) : null}
                  {data.profile_by_id?.twiiter ? (<a
                    href={`https://www.twitter.com/${data?.profile_by_id?.twitter}`}
                  >
                    <Icon
                      path={mdiTwitter}
                      size={1}
                      className="hover:text-[#1DA1F2] transition-colors duration-150"
                    />
                  </a>) : null}
                  {data.profile_by_id?.linkedin ? (<a
                    href={`https://www.linkedin.com/in/${data?.profile_by_id?.linkedin}`}
                  >
                    <Icon
                      path={mdiLinkedin}
                      size={1}
                      className="hover:text-[#0077b5] transition-colors duration-150"
                    />
                  </a>) : null}
                  {data.profile_by_id?.youtube ? (<a
                    href={`https://www.youtube.com/channel/${data?.profile_by_id?.youtube}`}
                  >
                    <Icon
                      path={mdiYoutube}
                      size={1}
                      className="hover:text-[#FF0000] transition-colors duration-150"
                    />
                  </a>) : null}
                  {data.profile_by_id?.instagram ? (<a 
                    href={`https://www.instagram.com/${data?.profile_by_id?.instagram}`}
                  >
                  <Icon
                    path={mdiInstagram}
                    size={1}
                    className="hover:text-[#C13584] transition-colors duration-150"
                  />
                  </a>) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="flex flex-col py-4">
        {data?.profile_by_id?.team_member &&
        data?.profile_by_id?.team_member[0] ? (
          <section className="mb-12">
            <h2 className="max-w-6xl text-4xl text-white font-header font-bold mx-auto w-screen p-2">
              Teams
            </h2>
            <div className="max-w-6xl w-screen grid grid-cols-2 mx-auto gap-8 text-white">
              {data?.profile_by_id?.team_member.map(
                (member: any, i: number) => {
                  return (
                    <div
                      className="col-span-1 bg-gray-650 p-2 rounded-md flex items-center hover:bg-gray-750 transition-all duration-75"
                      key={"teammember" + i}
                    >
                      <div className="bg-gray-800 w-12 h-12 rounded-md mr-4 flex-shrink-0">
                        <img
                          src={imageIdToURL(member.team.game.icon?.id)}
                          alt={member.team.game.name + " logo"}
                          className="w-8 h-8 m-2"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="tracking-wide font-header text-sm font-bold">
                          {member.team.name}
                          {member.username && member.username !== "N/A"
                            ? ` - "${member.username}"`
                            : null}
                        </div>
                        <div className="text-xs text-gray-200">
                          {member.rank
                            .map((rankname: string) => {
                              return TeamPositionMap[rankname];
                            })
                            .join(", ")}
                          {member.position ? ` - ${member.position}` : null}
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </section>
        ) : null}

        {data?.profile_by_id?.board_member &&
        data?.profile_by_id?.board_member[0] ? (
          <section className="mb-12">
            <div className="max-w-6xl text-4xl text-white font-header font-bold mx-auto w-screen p-2">
              Board Membership
            </div>
            <div className="max-w-6xl w-screen grid grid-cols-2 mx-auto gap-8 text-white">
              <div className="col-span-1 bg-gray-650 p-2 rounded-md flex items-center hover:bg-gray-750 transition-all duration-75">
                <div className="bg-gray-800 w-12 h-12 rounded-md mr-4 flex-shrink-0">
                  <Icon path={mdiShield} className="w-8 h-8 m-2" />
                </div>
                <div className="flex flex-col">
                  <div className="tracking-wide font-header text-sm font-bold">
                    {
                      BoardPositionMap[
                        data?.profile_by_id?.board_member[0]?.position
                      ]
                    }
                  </div>
                  <div className="text-xs text-gray-200">Test</div>
                </div>
              </div>
              {data?.profile_by_id?.board_member[0].committee_members.map(
                (member: any, i: number) => {
                  return (
                    <div
                      className="col-span-1 bg-gray-650 p-2 rounded-md flex items-center hover:bg-gray-750 transition-all duration-75"
                      key={"boardmember" + i}
                    >
                      <div className="bg-gray-800 w-12 h-12 rounded-md mr-4 flex-shrink-0">
                        <Icon path={mdiShield} className="w-8 h-8 m-2" />
                      </div>
                      <div className="flex flex-col">
                        <div className="tracking-wide font-header text-sm font-bold">
                          {parse_position_name(member.position)}
                        </div>
                        <div className="text-xs text-gray-200">
                          {member.committee.name}
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </section>
        ) : null}
        {!loading && !error ? (
          data?.profile_by_id?.bio ? (
            <>
              <div className="max-w-6xl text-4xl text-white font-header font-bold mx-auto w-screen p-2">
                Bio
              </div>
              <div
                className="max-w-6xl w-screen prose-content mx-auto px-4 sm:px-6 pb-8"
                dangerouslySetInnerHTML={{
                  __html: md.render(data.profile_by_id.bio),
                }}
              ></div>
            </>
          ) : null
        ) : (
          <div className="prose-content mx-auto px-4 sm:px-6 pb-8 py-4">
            {[...Array(3)].map((_, i) => {
              return (
                <div className="w-full" key={"paragraphskeleton" + i}>
                  <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                  <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                  <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                  <div className="w-full bg-gray-600 h-6 mb-2 animate-pulse"></div>
                  <div className="w-2/3 bg-gray-600 h-6 mb-4 animate-pulse"></div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const getHighestPosition = (profile: any) => {
  if (profile.board_member && profile.board_member[0]) {
    return BoardPositionMap[profile.board_member[0].position]
      ? BoardPositionMap[profile.board_member[0].position].toUpperCase()
      : profile.board_member[0].position.toUpperCase();
  } else if (profile.team_member && profile.team_member[0]) {
    //return profile.team_member.map((member: any) => {
    //  return member.team.name.toUpperCase();
    //}).join(", ");
    return "TEAM MEMBER";
  }
  return "";
};


type positionType = {
  [key: string]: string;
};

const BoardPositionMap: positionType = {
  president: "Board President",
  vice_president: "Board Vice President",
  treasurer: "Treasurer",
  secretary: "Secretary",
  team_captain: "Team Captain",
  community_manager: "Community Manager",
  diversity_and_inclusion_director: "Diversity and Inclusion Director",
  committee_head: "Committee Head",
  member_at_large: "Board Member",
  staff: "Staff",
};

const TeamPositionMap: positionType = {
  captain: "Team Captain",
  assistant_captain: "Assistant Captain",
  coach: "Coach",
  igl: "IGL",
  player: "Player",
  reserve: "Reserve",
  substitute: "Substitute",
};

function parse_position_name(position: string) {
  let words = position.split("_");
  words = words.map((word: string) => { return word.charAt(0).toUpperCase() + word.slice(1); });
  return words.join(" ");
}

export default Profile;