import React from "react";
import { Link } from "react-router-dom";
import { TagType } from "../../types";
import { imageIdToURL } from "../../utils/LinkUtils";
import Tag from "./Tag";

type ArticleBlockProps = {
  loading?: boolean;
  title: string;
  subtitle: string;
  link: string;
  author_name: string;
  author_image?: string;
  profile_link: string;
  image_link?: string;
  date: string;
  tags: Array<any>;
  hidelast?: boolean; // Hides last article on large screens. Useful for layout purposes.
};

function ArticleBlock(props: ArticleBlockProps) {
  if (props.loading) {
    return (
      <div className="flex flex-col h-full">
        <figure className="relative h-0 pb-9/16 bg-gray-600 animate-pulse mb-4"></figure>
        <figure className="h-6 w-48 bg-gray-600 animate-pulse mb-4"></figure>
        <figure className="h-4 w-full bg-gray-600 animate-pulse mb-2"></figure>
        <figure className="h-4 w-full bg-gray-600 animate-pulse mb-2"></figure>
        <figure className="h-4 w-24 bg-gray-600 animate-pulse mb-2"></figure>
      </div>
    );
  }
  return (
    <article
      className={
        "flex flex-col h-full" + (props.hidelast ? " last:lg:hidden" : "")
      }
    >
      <header>
        <Link className="block mb-4" to={props.link}>
          <figure className="relative h-0 pb-9/16 overflow-hidden">
            <img
              className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
              src={props.image_link}
              alt="News 01"
            />
          </figure>
        </Link>
        <div className="mb-3">
          <ul className="flex flex-wrap text-xs font-medium -m-1">
            {props.tags
              .map((tag: any) => {
                return tag.tags_id;
              })
              .map((tag: TagType, i: number) => {
                return (
                  <Tag
                    color={tag.color}
                    text={tag.text}
                    key={props.title + props.date + i}
                  />
                );
              })}
          </ul>
        </div>
        <Link className="hover:underline" to={props.link}>
          <h3 className="text-2xl font-header font-bold text-white mb-2">
            {props.title}
          </h3>
        </Link>
      </header>
      <p className="text-gray-600 dark:text-gray-400 flex-grow">
        {props.subtitle}
      </p>
      <footer className="flex items-center mt-4">
        <a href="#0">
          <img
            className="rounded-full flex-shrink-0 mr-3"
            src={imageIdToURL(
              props.author_image,
              `https://ui-avatars.com/api/?name=${props.author_name}&color=cccccc&background=333333`
            )}
            width="32"
            height="32"
            alt="Author 03"
          />
        </a>
        <div className="text-sm text-gray-500">
          By{" "}
          <Link
            className="font-medium text-gray-800 dark:text-gray-400 hover:underline"
            to={props.profile_link}
          >
            {props.author_name}
          </Link>{" "}
          · <span className="text-gray-500">{props.date}</span>
        </div>
      </footer>
    </article>
  );
}

export default ArticleBlock;
