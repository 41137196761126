import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
});

// this function is mostly useless - it was previously used for authentication
// and passing the auth token. Leaving it here in case we ever need that
// functionality in the future.

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
    },
  };
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(link),
});

export default client;
